import React, { Component } from 'react';
import $ from 'jquery';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'
import arrayMove from "./arrayMove";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { copyImageToClipboard } from 'copy-image-clipboard';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button, TextField, Table, TableSortLabel, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default class WorkFlowProgress extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			workflows:[],
			wCategories:[],
			wSubCategories:[],
			types:[],
			wInsurance:[],
			wPatients:[],
			wPatientNames:[],
			responsibleRole:[],
			responsiblePerson:[],
			filterCategory:'',
			filterSubCategory:'',
			filterLabel:'',
			filterWorkflow:'',
			workflowFirstTask:[],
			workflowFirstTaskFilter:[],
			workflowSuperJobs:[],
			workflowJob:[],
			workflowJobTasks:[],
			rolePerson:[],
			branchs:[],
			tasks:[],
			taskNote:'',
			checklist:[],
			taskDetails:[],
			taskName:'',
			taskWipNote:'',
			startDate:'',
			endDate:'',
			workflowListTable:true,
			viewWorkflowDetails:false,
			superViewIcon:false,
			superViewShow:false,
			superViewV2:false,
			simpleView:false,
			showNewLabel:false,
			saveBtn:false,
			workflowDaysSpent:0,
			greaterCount:0,
			checklistWid:'',
			checklistTaskId:'',
			listPercentage:0,
			copyQrCode:'',
			uniqueQrCode:'',
			searchQuery:'',
			page:0,
			page1:0,
			page2:0,
			page3:0,
			rowsPerPage:10,
			rowsPerPage1:10,
			rowsPerPage2:10,
			rowsPerPage3:10,
			simpleViewArray:[],
			labels:[],
			newColor:'#ffffff',
		}

    }
	
	componentDidMount() { 
		this.getWorkflow();
		this.getTaskMasterUsers();
		this.userSimpleView();
		this.getLabels();
		$(document).mouseup(function (e) {
            let checkList = document.getElementById('list1');
			if (checkList && $(e.target).closest("#list1").length === 0) { 
				if (checkList.classList.contains('visible')){
					checkList.classList.remove('visible');
				}
			}
        }); 
	}
	
	getTaskMasterUsers=() => {
		
		let user = localStorage.getItem('username');
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'authors-responsible';
		let data = [];
		axios.get(url)
        .then(response => {
			data = response.data;
			
			
			let userDt = [];
		
			data.map(function(val,n) {
				if(user == val.user_name){
					userDt = val.responsible_role;
				}else if(val.id == 1){
					userDt = val.responsible_role;
				}
			})
			
			let rolePerson = [];
			let responsibleRole = Object.keys(userDt);
			
			responsibleRole.map(function(val,n) {
				let persons = userDt[val];
				let person = [];
				persons.map(function(vl,n) {
					person.push(vl.person+'|'+vl.person_id+'|'+vl.person_email);
				})
				
				rolePerson[val] = person.join(",");
				
			})
			
			this.setState({userData:data,responsibleRole,rolePerson});
			
		}).catch(error => {
			alert('error::'+ error);
		})
    }
	
	getWorkflow=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'pro-workflows';
		let data = [];
		axios.get(url)
        .then(response => {
			data = response.data;
			//console.log('data->',data);
			if(data){
				let wName = [];
				let wCategory = [];
				let wSubCategory = [];
				let wRole = [];
				let wPerson = [];
				let wBranch = [];
				let wType = [];
				let wcompany = [];
				let wPatient = [];
				let wPatientName = [];
				
				let task = [];

				data.map(function(val,i) {
					wName.push(val['name']);
					wCategory.push(val['category']);
					//wSubCategory.push(val['subcategory']);
					wBranch.push(val['branch_id']);
					wType.push(val['type']);
					wcompany.push(val['insurance_company']);
					wPatient.push(val['patient_id']);
					task.push(val['task']['name']);
					wPatientName.push(val['patient_first_name']+' '+val['patient_last_name']);
					if(val['task']['role']){
						wRole.push(val['task']['role']);
						wPerson.push(val['task']['person']);
					}
					if(val['label']){
						
						that.state.labels.map(function(lb,i) {
							if(val['label'] == lb['id']){
								val['label_name'] = lb['name'];
							}
						})	
					}
					
					val['x_days_number'] = val['task']['x_days_number'];
					val['days_spent'] = val['task']['days_spent'];
					val['updated_at'] = val['task']['updated_at'];
				})
				
				
				let workflows = this.uniqueArray(wName);
				let wCategories = this.uniqueArray(wCategory);
				//let wSubCategories = this.uniqueArray(wSubCategory);
				let responsibleRole = this.uniqueArray(wRole);
				let responsiblePerson = this.uniqueArray(wPerson);
				let branchs = this.uniqueArray(wBranch);
				let types = this.uniqueArray(wType);
				let wInsurance = this.uniqueArray(wcompany);
				let wPatients = this.uniqueArray(wPatient);
				let wPatientNames = this.uniqueArray(wPatientName);
				let tasks = this.uniqueArray(task);
				
				this.setState({workflows,wCategories,responsibleRole,responsiblePerson,branchs,types,wInsurance,wPatients,wPatientNames,tasks,workflowFirstTask:data});
				
				setTimeout(function(){
					that.dataFilter();
				}, 1000);
			}
        })
	}
	
	updateTaskOption=(event)=>{
		//let str = event.target.id;
		//let ids = str.split('|');
		
		let str = event.target.name;
		let ids = str.split('|');
		let val = event.target.value;
		let days = 0;
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'update-option-v2';
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
		let currentDate = moment(date).format('MM-DD-YYYY');
		
		let startDate = moment(ids[1]).format('MM/DD/YYYY');
		
		if(val == 'Complete'){
			let startDay = new Date(startDate);  
			let endDay = new Date(date);
			let millisBetween = startDay.getTime() - endDay.getTime();  
			days = millisBetween / (1000 * 3600 * 24);
		}
		
		let currentDate2 = moment(date).format('YYYY-MM-DD HH:MM');
		let formData = new FormData();
		formData.append('Id', ids[0]);
		formData.append('option', val);
		formData.append('date', currentDate);
		formData.append('days', days);
		formData.append('user', localStorage.getItem('username'));
		formData.append('updated_at', currentDate2);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			this.getWorkflow();
			this.getWorkflowJobDetails(this.state.viewWorkflowJobId);
		}).catch(error => {
			alert('error::'+ error);
		})
		
	}
	
	updatePerson=(event)=>{

		let rolePerson = this.state.rolePerson;
		
		let str = event.target.name;
		let ids = str.split('|');
		let val = event.target.value;
		let persons = rolePerson[ids[1]].split(',');
		let personData = [];
		persons.map(function(st,i) {
			let name = st.split('|');
			if(name[0] == val){
				personData = st;
			}
			
		})
		
		let name = personData.split('|');
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'update-person';
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
		let currentDate = moment(date).format('YYYY-MM-DD HH:MM');
		let formData = new FormData();
		formData.append('Id', ids[0]);
		formData.append('person_id', name[1]);
		formData.append('person', name[0]);
		formData.append('person_email', name[2]);
		formData.append('user', localStorage.getItem('username'));
		formData.append('updated_at', currentDate);
		
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			this.getWorkflow();
			this.getWorkflowJobDetails(this.state.viewWorkflowJobId);
		}).catch(error => {
			alert('error::'+ error);
		}) 
		
	}

	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		if(name == 'filterWorkflow' && res != ''){
			this.setState({superViewIcon:true});
		}else if(name == 'filterWorkflow' && res == ''){
			this.setState({superViewIcon:false});
		}
		
		if(name == 'filterCategory'){
			this.setState({filterSubCategory:''});
			this.getSubCategories(res);
		}

		this.setState({[event.target.name]:event.target.value});
		if(name != 'addWipNote'){
			let that = this;
			setTimeout(function(){
				that.dataFilter();
			}, 1000);
		}
	}
	
	getSubCategories=(name)=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'workflow-sub-categories-name/'+name;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({wSubCategories:data});
        })
	}
	
	detailsNote=(note)=>{
		this.setState({taskNote:note});
	}
	
	taskDetails=(data)=>{
		this.setState({taskDetails:data});
	}
	
	taskChecklist=(id,task_uid,task_name,job_id,wid,taskId,checklist,status_change,task_start_date)=>{
		let checklists = checklist.split(',');
		if(checklists.length > 0){
			let checkedCount = 0;
			
			checklists.map((val, index) => {
				let listtext = val.split(':');
				
				if(listtext[1] == 'true'){
					checkedCount= checkedCount+1;
				}
				
			})
			
			let listPercentage = checkedCount/checklists.length*100;
			
			this.setState({tId:id,taskUid:task_uid,taskName:task_name,checklist:checklists ? checklists : [],noteJobId:job_id,checklistWid:wid,checklistTaskId:taskId,checkedCount,listPercentage,status_change,task_start_date});
		}
	}
	
	wipNote=(job_id,task_uid,wid,task_id)=>{
		this.getWipNote(task_uid);
		//this.setState({taskWipNote:note});
		this.setState({noteJobId:job_id,taskUid:task_uid,noteWId:wid,noteTaskId:task_id});
	}
	
	handleWipNote=()=>{
		
		let noteJobId = this.state.noteJobId;
		let taskUid = this.state.taskUid;
		let noteTaskId = this.state.noteTaskId;
		let addWipNote = this.state.addWipNote;
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'add-task-note';
		//let date = new Date();
		//let currentDate = moment(date).format('MM/DD/YYYY HH:MM');
		let formData = new FormData();
		formData.append('task_uid', taskUid);
		formData.append('note', addWipNote);
		//formData.append('date', currentDate);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			//this.getWorkflow();
			this.getWipNote(taskUid);
			this.getWorkflow();
			if(this.state.viewWorkflowJobId){
				this.getWorkflowJobDetails(this.state.viewWorkflowJobId);
			}
			//$('#taskWipNote').modal('hide');
			this.setState({addWipNote:''});
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	handleWipNoteByRow=(job_id,wid,task_id,row_id) => {
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'task-note';
		let data = [];
		let formData = new FormData();
		formData.append('job_id', job_id);
		formData.append('wid', wid);
		formData.append('task_id', task_id);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			data = response.data;
			let notes = data.join("<br />");
			if(data != ''){
				$('#note_'+row_id).html(notes);
			}else{
				$('#note_row'+row_id).addClass('hide-row');
			}
			
		}).catch(error => {
			//alert('error::'+ error);
		})
    }
	
	handleViewWipNoteByRow=(job_id,wid,task_id,row_id) => {
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'task-note';
		let data = [];
		let formData = new FormData();
		formData.append('job_id', job_id);
		formData.append('wid', wid);
		formData.append('task_id', task_id);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			data = response.data;
			let notes = data.join("<br />");
			if(data != ''){
				$('#new_note_'+row_id).html(notes);
			}else{
				$('#note_row'+row_id).addClass('hide-row');
			}
		}).catch(error => {
			alert('error::'+ error);
		})
    }
	
	getWipNote=(task_uid) => {
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'task-note';
		let data = [];
		let formData = new FormData();
		formData.append('task_uid', task_uid);
		//formData.append('wid', wid);
		//formData.append('task_id', task_id);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			data = response.data;
			let notes = data.join("<br />");
			$('#10').html(notes);
			$('#wipNote').html(notes);
		}).catch(error => {
			alert('error::'+ error);
		})

    }
	
	uniqueArray(arr) {
		var a = [];
		for (var i=0, l=arr.length; i<l; i++)
			if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
				a.push(arr[i]);
		return a;
	}
	
	handleChangeStart = date => {
        this.setState({
          startDate: date,date
        });
		
		let that = this;
		setTimeout(function(){
			that.dataFilter();
		}, 1000);
    }
	
	handleChangeEnd = date => {
        this.setState({
          endDate: date,date
        });
		
		let that = this;
		setTimeout(function(){
			that.dataFilter();
		}, 1000);
    }
	
	resetFilter = () => {
		this.setState({searchQuery:'',filterWorkflow:'',filterCategory:'',filterSubCategory:'',filterRole:'',filterPerson:'',filterStatus:'',filterBranch:'',filterType:'',filterCompany:'',filterPatient:'',filterPatientName:'',filterTasks:'',startDate:'',endDate:''});
		$('.wp-filter select').val('');
		let that = this;
		setTimeout(function(){
			that.dataFilter();
		}, 1000);
	}
	
	workflowDetails = (workflowJobId,workflow_days_spent) => {
		this.setState({viewWorkflowJobId:workflowJobId,viewWorkflowDetails:true,workflowListTable:false,workflowJobTasks:[],workflowDaysSpent:workflow_days_spent});
		this.getWorkflowJobDetails(workflowJobId);
	}
	
	workflowDetailsV2 = (workflowJobId) => {
		this.setState({superViewV2:true,viewWorkflowJobId:workflowJobId,viewWorkflowDetails:true,workflowListTable:false,workflowJobTasks:[]});
		this.getWorkflowJobDetails(workflowJobId);
	}
	
	handleBackView = () => {
		this.setState({viewWorkflowJobId:'',viewWorkflowDetails:false,workflowListTable:true,superViewShow:false});
	}
	
	handleBackViewV2 = () => {
		this.setState({viewWorkflowDetails:false,workflowListTable:false,superViewShow:true});
	}
	
	handleSimpleView = () => {
		this.setState({simpleView:true});
	}
	
	handleFullView = () => {
		this.setState({simpleView:false});
	}
	
	getWorkflowJobDetails=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'workflow-job-details/'+id;
		
		axios.get(url)
        .then(response => {
			let data = response.data;
			this.setState({workflowJob:data,workflowJobTasks:data.tasks});
		}).catch(error => {
			alert('error::'+ error);
		})
		
	}
	
	superView = (superViewId) => {
		this.setState({workflowSuperJobs:[]});
		let ApiUrl = $('#ApiUrl').val();
		//let url = ApiUrl+'workflow/workflows_in_progress_v2.php';
		let url = ApiUrl+'workflows-in-progress-v2/'+superViewId;
		axios.get(url)
        .then(response => {
			let data = response.data;
			let greaterCount = 0;
			let workflowSuperJobs = data.jobs;
			workflowSuperJobs.map(function(row,i) {
				let superJobTask = row.Tasks;
				let count = superJobTask.length;
				if(count > greaterCount){
					greaterCount = count;
				}
			});
			
			this.setState({workflowSuperJobs:data.jobs,greaterCount});
		}).catch(error => {
			alert('error::'+ error);
		})
		
		this.setState({superViewShow:true,viewWorkflowDetails:false,workflowListTable:false});
	}
	
	showAllNotes =()=> {
		if($("input[name='showAllNotes']").prop("checked") == true){
			$('.note-row').show();
		}else{
			$('.note-row').hide();
		}
	}
	
	workflowFullView =()=> {
		if($("input[name='workflowFullView']").prop("checked") == true){
			$('.hide-item').addClass('all-show');
		}else{
			$('.hide-item').removeClass('all-show');
		}
	}
	
	showSelectedFields  = (e) => {
		let item = e.target.name;
		let simpleView = this.state.simpleViewArray;
		let isChecked = e.target.checked;
        if(isChecked == true){
			$('.'+item).removeClass('hide-item');
			$('.'+item).show();
			simpleView.push(item);
			this.setState({simpleViewArray:simpleView});
		}else{
			let newArr = [];
			$('.'+item).addClass('hide-item');
            $('.'+item).hide();
			
			simpleView.map((val) => {
				if(val != item){
					newArr.push(val);
				}
			})
			this.setState({simpleViewArray:newArr});
        }
    }
	
	saveChecklistOption =()=> {
		let tId = this.state.tId;
		let task_start_date = this.state.task_start_date;
		let checklist = this.state.checklist.join();
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'update-workflow-task-checklist';
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
		let currentDate = moment(date).format('YYYY-MM-DD HH:MM');
		let formData = new FormData();
		formData.append('tId', tId);
		formData.append('checklist', checklist);
		formData.append('updated_at', currentDate);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			let checkedCount = this.state.checkedCount;
			let status_change = this.state.status_change;
			if(checkedCount == this.state.checklist.length && status_change == 'true'){
				this.updateTaskToComplete(tId,task_start_date);
			}
			this.getWorkflow();
			this.getWorkflowJobDetails(this.state.noteJobId);
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	updateTaskToComplete=(tId,task_start_date)=>{
		let val = 'Complete';
		let days = 0;
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'update-option-v2';
		let date = new Date();
		let currentDate = moment(date).format('MM-DD-YYYY');
		
		let startDate = moment(task_start_date).format('YYYY-MM-DD');
		
		if(val == 'Complete'){
			let startDay = new Date(startDate);  
			let endDay = new Date(date);
			let millisBetween = startDay.getTime() - endDay.getTime();  
			days = millisBetween / (1000 * 3600 * 24);
		}
		let currentDate2 = moment(date).format('YYYY-MM-DD HH:MM');
		
		
		let formData = new FormData();
		formData.append('Id', tId);
		formData.append('option', val);
		formData.append('date', currentDate);
		formData.append('days', days);
		formData.append('user', localStorage.getItem('username'));
		formData.append('updated_at', currentDate2);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			this.getWorkflow();
		}).catch(error => {
			alert('error::'+ error);
		})
		
	}
	
	handalChecklistOption =(name)=> {
		let checklist = this.state.checklist;
		let updateChecklist = [];
		let that = this;
		if($("input[name='"+name+"']").prop("checked") == true){
			
			if(checklist.length > 0){
				checklist.map(function(val,i) {
					let str = val.split(':');
					if(str[0] == name){
						updateChecklist.push(str[0]+':'+'true');
						let note = 'Checklist item completed: '+name+' - by '+localStorage.getItem('username');
						that.handleChecklistNote(note);
					}else{
						updateChecklist.push(str[0]+':'+str[1]);
					}
				})
				this.setState({checklist:updateChecklist});
			}
					
		}else{
			if(checklist.length > 0){
				checklist.map(function(val,i) {
					let str = val.split(':');
					if(str[0] == name){
						updateChecklist.push(str[0]+':'+'false');
						let note = 'Checklist item unchecked: '+name+' - by '+localStorage.getItem('username');
						that.handleChecklistNote(note);
					}else{
						updateChecklist.push(str[0]+':'+str[1]);
					}
				})
				this.setState({checklist:updateChecklist});
			}
		}
		
		
		if(updateChecklist.length > 0){
			let checkedCount = 0;
			updateChecklist.map((val, index) => {
				let listtext = val.split(':');
				
				if(listtext[1] == 'true'){
					checkedCount= checkedCount+1;
				}
				
			})
			
			let listPercentage = checkedCount/updateChecklist.length*100;
			
			this.setState({checkedCount,listPercentage});
		}
		
		
	}
	
	handleChecklistNote=(note)=>{
		
		let jobId = this.state.noteJobId;
		let taskUid = this.state.taskUid;
		let wId = this.state.checklistWid;
		let taskId = this.state.checklistTaskId;
		//let note = name+' - Completed by '+localStorage.getItem('username');
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'add-task-note';
		//let date = new Date();
		//let currentDate = moment(date).format('MM/DD/YYYY HH:MM');
		let formData = new FormData();
		formData.append('task_uid', taskUid);
		formData.append('note', note);
		//formData.append('date', currentDate);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			this.getWipNote(taskUid);
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	getQrCode=(task_uid,wname,tname)=>{
		//console.log('task_uid->',task_uid);
		let qrCode = "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data="+task_uid;
		this.setState({uniqueQrCode:qrCode,copyQrCode:task_uid,workflowName:wname,taskName:tname});
	}
	
	copyQrImage=(img)=>{
		let path = "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data="+img;
		copyImageToClipboard(path).then(() => {
		  console.log('Image Copied');
		}).catch((e) => {
		  console.log('Error: ', e.message);
		})
	}
	
	saveQrImage=(img,fileName)=>{
		let url = "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data="+img;
		var xhr = new XMLHttpRequest();
		xhr.open("GET", url, true);
		xhr.responseType = "blob";
		xhr.onload = function(){
			var urlCreator = window.URL || window.webkitURL;
			var imageUrl = urlCreator.createObjectURL(this.response);
			var tag = document.createElement('a');
			tag.href = imageUrl;
			tag.download = fileName+'.png';
			document.body.appendChild(tag);
			tag.click();
			document.body.removeChild(tag);
		}
		xhr.send();
	}
	
	printQrImage=()=>{
		let w = window.open();
		let html = $("#qr-code-img").html();
		$(w.document.body).html(html);
		setTimeout(function(){w.print()}, 1000);
	}
	
	handleChangePage = (event: unknown, newPage: number) => {
		this.setState({page:newPage});
	};
	
	handleChangePage1 = (event: unknown, newPage: number) => {
		this.setState({page1:newPage});
	};

	handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({rowsPerPage:event.target.value,page:0});
	};
	
	handleChangeRowsPerPage1 = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({rowsPerPage1:event.target.value,page1:0});
	};
	
	handleChangePage2 = (event: unknown, newPage: number) => {
		this.setState({page2:newPage});
	};

	handleChangeRowsPerPage2 = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({rowsPerPage2:event.target.value,page2:0});
	};
	
	handleChangePage3 = (event: unknown, newPage: number) => {
		this.setState({page3:newPage});
	};

	handleChangeRowsPerPage3 = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({rowsPerPage3:event.target.value,page3:0});
	};
	
	filedFilter=()=>{
		let checkList = document.getElementById('list1');
		if (checkList.classList.contains('visible'))
			checkList.classList.remove('visible');
		else
			checkList.classList.add('visible');
	}
	
	userSimpleView = () => {
		let username = localStorage.getItem('username');
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'simple-view/'+username;
		
		axios.get(url)
        .then(response => {
			let data = response.data;
			if(data.view){
				let viewArr = data.view.split(',');
				this.setState({simpleViewArray:viewArr});
			}
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	saveSimpleView=()=>{
		let user = localStorage.getItem('username');
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'save-simple-view';
		let simpleView = this.state.simpleViewArray;
		let formData = new FormData();
		formData.append('username', user);
		formData.append('simpleView', simpleView);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			alert('The simple view saved successfully!');
			let checkList = document.getElementById('list1');
			checkList.classList.remove('visible');
		}).catch(error => {
			console.log('error::', error);
		}) 
	}
	
	gotoLinkUrl=(row)=>{
		if(row.task.status != 'In Progress'){
			this.updateTaskOptionByGoto(row);
		}
		//window.open(row.task.gotolink);
	}
	
	updateTaskOptionByGoto=(row)=>{
		let days = 0;
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'update-option-v2';
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
		let currentDate = moment(date).format('MM-DD-YYYY');
		let startDate = moment(row.task.task_start_date).format('MM/DD/YYYY');
		let currentDate2 = moment(date).format('YYYY-MM-DD HH:MM');
		
		let formData = new FormData();
		formData.append('Id', row.task.id);
		formData.append('option', 'In Progress');
		formData.append('date', currentDate);
		formData.append('days', days);
		formData.append('user', localStorage.getItem('username'));
		formData.append('updated_at', currentDate2);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			this.getWorkflow();
			this.getWorkflowJobDetails(this.state.viewWorkflowJobId);
		}).catch(error => {
			alert('error::'+ error);
		})
		
	}
	
	handleSort = column => {
		const { sortBy, sortOrder } = this.state;
		const isAsc = sortBy === column && sortOrder === 'asc';
		this.setState({
		  sortBy: column,
		  sortOrder: isAsc ? 'desc' : 'asc'
		});
	};
	
	getLabels=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'labels';
		axios.get(url)
        .then(response => {
			let data = response.data;
			console.log('data->',data);
			this.setState({labels:data});
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	handleColorBg = (e) => {
		let item = e.target.name;
		let val = e.target.value;
        this.setState({[item]:val});
    }
	
	saveLabel=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'add-label';
		let name = this.state.newLabel;
		let color = this.state.newColor;
		let uid = ulid();

		let formData = new FormData();
		formData.append('uid', uid);
		formData.append('name', name);
		formData.append('color', color);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			this.getLabels();
			this.setState({showNewLabel:false,newLabel:'',newColor:'#ffffff'})
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	showAddLabel=()=>{
		 this.setState({showNewLabel:true});
	}
	
	hideNewLabel=()=>{
		 this.setState({showNewLabel:false});
	}
	
	hideEditLabel=()=>{
		 this.setState({editLable:0});
	}
	
	editLabel=(id)=>{
		 this.setState({editLable:id});
	}
	
	deleteLabel=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'delete-label/'+id;
		axios({
			method: 'delete',
			url: url,
		})
		.then(response => {
			this.getLabels();
			let msg = response.data;
			alert(msg);
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	updateLabel=(id,name,color)=>{
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'update-label';
		let labelName = this.state.labelName ? this.state.labelName : name;
		let Labelcolor = this.state['bg-'+id] ? this.state['bg-'+id] : color;

		let formData = new FormData();
		formData.append('id', id);
		formData.append('name', labelName);
		formData.append('color', Labelcolor);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			this.getLabels();
			//this.getWorkflow();
			this.setState({editLable:0});
			//$('#colorPopup').modal('hide');
		}).catch(error => {
			alert('error::'+ error);
		})
		
	}
	
	handleColorCheckbox=(id)=>{
		 this.setState({assignId:id, saveBtn:true});
	}
	
	handleWorkflowLabel=(id,label)=>{
		//$('#editOptionPopup').modal('show');
		this.setState({workflowLabelId:id,assignId:label});
	}
	
	assignLabelToWorkflow=()=>{

		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'assign-label';
		let workflowLabelId = this.state.workflowLabelId;
		let assignId = this.state.assignId;
		let uid = ulid();

		let formData = new FormData();
		formData.append('id', workflowLabelId);
		formData.append('label', assignId);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			this.getWorkflow();
			$('#colorPopup').modal('hide');
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	handleSearch = (event) => {
		let that = this;
		this.setState({searchQuery:event.target.value});
		setTimeout(function(){
			that.dataFilter();
		}, 1000);
		
		/* let workflowFirstTask = this.state.workflowFirstTask;
		let searchQuery = event.target.value;
		let workflowFilter = workflowFirstTask.filter((row) =>
			row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
			//row.label_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
			row.patient_first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
			row.patient_last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
			//row.insurance_company.toLowerCase().includes(searchQuery.toLowerCase()) ||
			row.task.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
			//row.task.role.toLowerCase().includes(searchQuery.toLowerCase()) ||
			row.task.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
			row.username.toLowerCase().includes(searchQuery.toLowerCase())
		);
		this.setState({workflowFirstTaskFilter:workflowFilter,searchQuery:event.target.value}); */
    };
	
	dataFilter=() => {
		let workflowFirstTaskFilter = [];
		let that = this;
		let wName = [];
		let wRole = [];
		let wPerson = [];
		let wBranch = [];
		let wType = [];
		let wcompany = [];
		let wPatient = [];
		let wPatientName = [];
		let task = [];
		this.state.workflowFirstTask.map(function(row,i) {
			
			let filterPatientName = row.patient_first_name+' '+row.patient_last_name;
			
			if(that.state.filterTasks && that.state.filterTasks != row.task.name){
				return false;
			}
			
			if(that.state.filterWorkflow && that.state.filterWorkflow != row.name){
				return false;
			}
			if(that.state.filterCategory && that.state.filterCategory != row.category){
				return false;
			}
			
			if(that.state.filterSubCategory && that.state.filterSubCategory != row.subcategoryname){
				return false;
			}
			
			if(that.state.filterLabel && that.state.filterLabel != row.label){
				return false;
			}
			
			if(that.state.filterRole && that.state.filterRole != row.task.role){
				return false;
			}
			
			if(that.state.filterPerson && that.state.filterPerson != row.task.person){
				return false;
			}
			
			if(that.state.filterStatus && that.state.filterStatus != row.task.status){
				return false;
			}
			
			if(that.state.filterBranch && that.state.filterBranch != row.branch_id){
				return false;
			}
			
			if(that.state.filterType && that.state.filterType != row.type){
				return false;
			}
			
			if(that.state.filterCompany && that.state.filterCompany != row.insurance_company){
				return false;
			}
			
			if(that.state.filterPatient && that.state.filterPatient != row.patient_id){
				return false;
			}
			
			if(that.state.filterPatientName && that.state.filterPatientName != filterPatientName){
				return false;
			}
			
			let start_date = moment(row.start_date).format('MM/DD/YYYY');
			let dueDate = moment(row.due_date).format('MM/DD/YYYY');
			
			if(that.state.startDate){
				let sDate = moment(that.state.startDate).format('MM/DD/YYYY');
				if(start_date <= sDate){
					return false;
				}
			}
			
			if(that.state.endDate){
				let eDate = moment(that.state.endDate).format('MM/DD/YYYY');
				if(dueDate >= eDate){
					return false;
				}
			}
			
			if(row.task.id){
				workflowFirstTaskFilter.push(row);
				wName.push(row.name);
				wBranch.push(row.branch_id);
				wType.push(row.type);
				wcompany.push(row.insurance_company);
				wPatient.push(row.patient_id);
				if(row.task){
					task.push(row.task.name);
					wPatientName.push(row.patient_first_name+' '+row.patient_last_name);
					if(row.task.role){
						wRole.push(row.task.role);
						wPerson.push(row.task.person);
					}
				}
			}
		});
		
		let searchQuery = this.state.searchQuery;
		
		if(searchQuery){
			workflowFirstTaskFilter = workflowFirstTaskFilter.filter((row) =>
				row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
				row.label_name && row.label_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
				row.patient_first_name && row.patient_first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
				row.patient_last_name && row.patient_last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
				row.insurance_company && row.insurance_company.toLowerCase().includes(searchQuery.toLowerCase()) ||
				row.task.name && row.task.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
				row.task.role && row.task.role.toLowerCase().includes(searchQuery.toLowerCase()) ||
				row.task.person && row.task.person.toLowerCase().includes(searchQuery.toLowerCase()) ||
				row.task.status && row.task.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
				row.username && row.username.toLowerCase().includes(searchQuery.toLowerCase())
			);
		}
		
		let workflows = this.uniqueArray(wName);
		let responsibleRole = this.uniqueArray(wRole);
		let responsiblePerson = this.uniqueArray(wPerson);
		let branchs = this.uniqueArray(wBranch);
		let types = this.uniqueArray(wType);
		let wInsurance = this.uniqueArray(wcompany);
		let wPatients = this.uniqueArray(wPatient);
		let wPatientNames = this.uniqueArray(wPatientName);
		let tasks = this.uniqueArray(task);

		this.setState({workflowFirstTaskFilter,page:this.state.assignId ? this.state.page : 0,workflows,responsibleRole,responsiblePerson,branchs,types,wInsurance,wPatients,wPatientNames,tasks,workflowLabelId:'',assignId:''});
	}
	
	render() {
		const {workflowFirstTask,sortBy,sortOrder,workflowFirstTaskFilter,workflows,wCategories,wSubCategories,filterWorkflow,filterCategory,filterSubCategory,filterRole,filterPerson,filterStatus,filterBranch,filterType,filterCompany,filterPatient,filterPatientName,filterTasks,responsibleRole,responsiblePerson,branchs,types,wInsurance,wPatients,wPatientNames,tasks,viewWorkflowDetails,workflowListTable,workflowJob,workflowJobTasks,superViewIcon,superViewShow,workflowSuperJobs,rolePerson,updateChecklist,checklist,taskDetails,simpleView,simpleViewArray,labels,showNewLabel,editLable,searchQuery} = this.state;
		
		console.log('workflowFirstTask->',workflowFirstTask);
		
		const sortedData = [...workflowFirstTaskFilter].sort((a, b) => {
		  const isAsc = sortOrder === 'asc';
		  if (a[sortBy] < b[sortBy]) {
			return isAsc ? -1 : 1;
		  }
		  if (a[sortBy] > b[sortBy]) {
			return isAsc ? 1 : -1;
		  }
		  return 0;
		});
		
		const StyledTableCell = styled(TableCell)(({ theme }) => ({
		  [`&.${tableCellClasses.head}`]: {
			backgroundColor: '#e9e9e9',
			color: '#000;',
			fontWeight: 600,
			fontSize: 15,
		  },
		  [`&.${tableCellClasses.body}`]: {
			fontSize: 14,
		  },
		}));
		
		let tasksHeader = '';
		let href = window.location.href.split('?')[0];
		let that = this;
		let superViewId = '';
		let assignedDays = '';
		
		if(workflowFirstTask && !viewWorkflowDetails){
			workflowFirstTask.map(function(row,i) {
				if(row.task.id){
					if(filterWorkflow && filterWorkflow != row.name){
						return false;
					}
					superViewId = row.workflow_id;
				}
			})

        }
		
		if(workflowJobTasks){
			let patient_first_name = workflowJobTasks.patient_first_name;
			assignedDays = 0;
			workflowJobTasks.map(function(row,i) {
				assignedDays += parseInt(row.x_days_number);
			})
        }
		
		if(workflowSuperJobs){

			workflowSuperJobs.map(function(row,i) {
				if(row.WF_Job_ID){
					let workflowSuperJobTask = row.Tasks;
					tasksHeader = workflowSuperJobTask.map(function(row2,n) {
						return (<TableCell style={{padding: '0px !important'}} className="task-header-th ext-fields"><div className="task-header-span">{row2.Task_name.substr(0,20)}</div></TableCell>);
					})
				}
			})
        }
		
		if(simpleView){
			tasksHeader = '';
		}
		
		let workflowOption = workflows.map(function(val,i) {
			return (
				<MenuItem value={val} key={i}>{val}</MenuItem>
			);
		})
		
		let categoryOption = wCategories.map(function(val,i) {
			return (
				<option value={val} key={i}>{val}</option>
			);
		})
		
		let subCategoryOption = wSubCategories.map(function(val,i) {
			return (
				<option value={val.name} key={i}>{val.name}</option>
			);
		})
		
		let roleOption = responsibleRole.map(function(val,i) {
			return (
				<option value={val} key={i}>{val}</option>
			);
		})
		
		let personOption = responsiblePerson.map(function(val,i) {
			return (
				<option value={val} key={i}>{val}</option>
			);
		})
		
		let branchOption = branchs.map(function(val,i) {
			return (
				<option value={val} key={i}>{val}</option>
			);
		})
		
		let typeOption = types.map(function(val,i) {
			return (
				<option value={val} key={i}>{val}</option>
			);
		})
		
		let companyOption = wInsurance.map(function(val,i) {
			return (
				<option value={val} key={i}>{val}</option>
			);
		})
		
		let patientOption = wPatients.map(function(val,i) {
			return (
				<option value={val} key={i}>{val}</option>
			);
		})

		if(workflowListTable){
			return (
				<div className="workflow-first-task">
					<div className="wdata-table-div">
						<div className="row">
							<div className="col-md-12 mb-3 text-center">
								<h4>Workflow in Progress</h4>
								<div className="show-all-note">
									<div className="show-all-notes">
									<label className="showAllSwitch">
										<input id="showAllNotes" type="checkbox" name="showAllNotes" onClick={()=>this.showAllNotes()} />
										<span className="slider round showAllSlide"></span>
									</label>
									<span><b>Show all Notes</b></span>
									</div>
									<div className="workflow-full-view">
										<label className="showAllSwitch">
											<input id="fullView" type="checkbox" name="workflowFullView" onClick={()=>this.workflowFullView()} />
											<span className="slider round showAllSlide"></span>
										</label>
										<span><b>Full View</b></span>
									</div>
									
									<div id="list1" className="dropdown-check-list" tabindex="100">
									  <span className="anchor" onClick={()=>this.filedFilter()}>Select Fields</span>
									  <ul className="items">
										<li><input type="checkbox" name="t-cate" checked={simpleViewArray.indexOf('t-cate') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Category</span></li>
										<li><input type="checkbox" name="t-subcate" checked={simpleViewArray.indexOf('t-subcate') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Subategory</span></li>
										
										<li><input type="checkbox" name="t-label" checked={simpleViewArray.indexOf('t-label') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Label</span></li>
										
										<li><input type="checkbox" name="t-role" checked={simpleViewArray.indexOf('t-role') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Responsible Role</span></li>
										<li><input type="checkbox" name="t-person" checked={simpleViewArray.indexOf('t-person') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Responsible Person</span></li>
										
										
										{/* <li><input type="checkbox" name="t-status" onChange={this.showSelectedFields}/><span className="li-span">Status</span></li> */}
										
										<li><input type="checkbox" name="t-icon" checked={simpleViewArray.indexOf('t-icon') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Status Icon</span></li>
										
										<li><input type="checkbox" name="t-start" checked={simpleViewArray.indexOf('t-start') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Start Date</span></li>
										<li><input type="checkbox" name="t-due" checked={simpleViewArray.indexOf('t-due') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Due Date</span></li>
										
										<li><input type="checkbox" name="t-activity" checked={simpleViewArray.indexOf('t-activity') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Last Activity</span></li>
										<li><input type="checkbox" name="t-days" checked={simpleViewArray.indexOf('t-days') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Assigned Days</span></li>
										<li><input type="checkbox" name="t-a-days" checked={simpleViewArray.indexOf('t-a-days') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Actual days</span></li>
										
										<li><input type="checkbox" name="t-checklist" checked={simpleViewArray.indexOf('t-checklist') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Checklist</span></li>
										<li><input type="checkbox" name="t-details" checked={simpleViewArray.indexOf('t-details') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Details</span></li>
										<li><input type="checkbox" name="t-note" checked={simpleViewArray.indexOf('t-note') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Note</span></li>
										<li><input type="checkbox" name="t-qr" checked={simpleViewArray.indexOf('t-qr') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">QR Code</span></li>
										<li><input type="checkbox" name="t-note-icon" checked={simpleViewArray.indexOf('t-note-icon') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">WIP Notes</span></li>
										<li><input type="checkbox" name="t-goto" checked={simpleViewArray.indexOf('t-goto') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Go-To</span></li>
										<li><input type="checkbox" name="t-link" checked={simpleViewArray.indexOf('t-link') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Link</span></li>
										<li><input type="checkbox" name="t-initated" checked={simpleViewArray.indexOf('t-initated') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Initated By</span></li>
										<li><input type="checkbox" name="t-type" checked={simpleViewArray.indexOf('t-type') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Type</span></li>
										<li><input type="checkbox" name="t-company" checked={simpleViewArray.indexOf('t-company') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Insurance Company</span></li>
										<li><input type="checkbox" name="t-branch" checked={simpleViewArray.indexOf('t-branch') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Branch</span></li>
										<li><input type="checkbox" name="t-fpatient" checked={simpleViewArray.indexOf('t-fpatient') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Patient First Name</span></li>
										<li><input type="checkbox" name="t-lpatient" checked={simpleViewArray.indexOf('t-lpatient') != -1 ? 'checked' : ''} onChange={this.showSelectedFields}/><span className="li-span">Patient Last Name</span></li>
									  </ul>
									</div>
									<div className="save-simple-view"><button className="btn btn-primary btn-sm" type="button" onClick={()=>this.saveSimpleView()}>Save Simple View</button></div>
								</div>
							</div>
						</div>
						
						<div className="workflow-filters">
							
							<div className="wp-filter">
							
									<TextField
										label="Search"
										size="small"
										variant="outlined"
										fullWidth
										value={searchQuery}
										onChange={this.handleSearch}
										style={{ width: '200px' }}
									/>
									<i className="fa fa-search search-icon" aria-hidden="true"></i>
								
							</div>
							
							<div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 158, maxWidth: 158 }} size="small">
									<InputLabel>Category</InputLabel>
									<Select
										value={this.state.filterCategory ? this.state.filterCategory : ""}
										name={'filterCategory'}
										onChange={this.getValue}
										input={<OutlinedInput label="Category" />}
									>
									<MenuItem value={''} >None</MenuItem>
									{wCategories.map(function(val,i) {
										return (
											<MenuItem value={val} key={i}>{val}</MenuItem>
										);
									})}
									</Select>
								</FormControl>
							</div>
							
							<div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 158, maxWidth: 158 }} size="small">
									<InputLabel>Subcategory</InputLabel>
									<Select
										value={this.state.filterSubCategory ? this.state.filterSubCategory : ""}
										name={'filterSubCategory'}
										onChange={this.getValue}
										input={<OutlinedInput label="Subcategory" />}
									>
									<MenuItem value={''} >None</MenuItem>
									{wSubCategories.map(function(val,i) {
										return (
											<MenuItem value={val.name} key={i}>{val.name}</MenuItem>
										);
									})}
									</Select>
								</FormControl>
							</div>
							
							<div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 158, maxWidth: 158 }} size="small">
									<InputLabel>Label</InputLabel>
									<Select
										value={this.state.filterLabel ? this.state.filterLabel : ""}
										name={'filterLabel'}
										onChange={this.getValue}
										input={<OutlinedInput label="Label" />}
									>
									<MenuItem value={''} >None</MenuItem>
									{labels.map(function(val,i) {
										return (
											<MenuItem value={val.id} key={i}>{val.name}</MenuItem>
										);
									})}
									</Select>
								</FormControl>
							</div>
							
							<div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120 }} size="small">
									<InputLabel>Workflow</InputLabel>
									<Select
										value={this.state.filterWorkflow ? this.state.filterWorkflow : ""}
										name={'filterWorkflow'}
										onChange={this.getValue}
										input={<OutlinedInput label="Workflow" />}
									>
									<MenuItem value={''} >None</MenuItem>
									{workflows.map(function(val,i) {
										return (
											<MenuItem value={val} key={i}>{val}</MenuItem>
										);
									})}
									</Select>
								</FormControl>
							</div>
							
							<div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120 }} size="small">
									<InputLabel>Task</InputLabel>
									<Select
										value={this.state.filterTasks ? this.state.filterTasks : ""}
										name={'filterTasks'}
										onChange={this.getValue}
										input={<OutlinedInput label="Task" />}
									>
									<MenuItem value={''} >None</MenuItem>
									{tasks.map(function(val,i) {
										return (
											<MenuItem value={val} key={i}>{val}</MenuItem>
										);
									})}
									</Select>
								</FormControl>
							</div>
							<div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120 }} size="small">
									<InputLabel>Role</InputLabel>
									<Select
										value={this.state.filterRole ? this.state.filterRole : ""}
										name={'filterRole'}
										onChange={this.getValue}
										input={<OutlinedInput label="Role" />}
									>
									<MenuItem value={''} >None</MenuItem>
									{responsibleRole.map(function(val,i) {
										return (
											<MenuItem value={val} key={i}>{val}</MenuItem>
										);
									})}
									</Select>
								</FormControl>
							</div>
							
							<div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120 }} size="small">
									<InputLabel>Person</InputLabel>
									<Select
										value={this.state.filterPerson ? this.state.filterPerson : ""}
										name={'filterPerson'}
										onChange={this.getValue}
										input={<OutlinedInput label="Person" />}
									>
									<MenuItem value={''} >None</MenuItem>
									{responsiblePerson.map(function(val,i) {
										return (
											<MenuItem value={val} key={i}>{val}</MenuItem>
										);
									})}
									</Select>
								</FormControl>
							</div>
							
							<div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120 }} size="small">
									<InputLabel>Status</InputLabel>
									<Select
										value={this.state.filterStatus ? this.state.filterStatus : ""}
										name={'filterStatus'}
										onChange={this.getValue}
										input={<OutlinedInput label="Status" />}
									>
									<MenuItem value={''} >None</MenuItem>
									<MenuItem value={'Pending'} >Pending</MenuItem>
									<MenuItem value={'To Do'} >To Do</MenuItem>
									<MenuItem value={'In Progress'} >In Progress</MenuItem>
									<MenuItem value={'Complete'} >Complete</MenuItem>
									<MenuItem value={'N/A'} >N/A</MenuItem>
									
									</Select>
								</FormControl>
							</div>
							
							<div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120 }} size="small">
									<InputLabel>Branch</InputLabel>
									<Select
										value={this.state.filterBranch ? this.state.filterBranch : ""}
										name={'filterBranch'}
										onChange={this.getValue}
										input={<OutlinedInput label="Branch" />}
									>
									<MenuItem value={''} >None</MenuItem>
									{branchs.map(function(val,i) {
										return (
											<MenuItem value={val} key={i}>{val}</MenuItem>
										);
									})}
									
									</Select>
								</FormControl>
							</div>
							
							<div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120 }} size="small">
									<InputLabel>Type</InputLabel>
									<Select
										value={this.state.filterType ? this.state.filterType : ""}
										name={'filterType'}
										onChange={this.getValue}
										input={<OutlinedInput label="Type" />}
									>
									<MenuItem value={''} >None</MenuItem>
									{types.map(function(val,i) {
										return (
											<MenuItem value={val} key={i}>{val}</MenuItem>
										);
									})}
									
									</Select>
								</FormControl>
							</div>
							
							<div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120 }} size="small">
									<InputLabel>Insurance Company</InputLabel>
									<Select
										value={this.state.filterCompany ? this.state.filterCompany : ""}
										name={'filterCompany'}
										onChange={this.getValue}
										input={<OutlinedInput label="Insurance Company" />}
									>
									<MenuItem value={''} >None</MenuItem>
									{wInsurance.map(function(val,i) {
										return (
											<MenuItem value={val} key={i}>{val}</MenuItem>
										);
									})}
									</Select>
								</FormControl>
							</div>
									
							
							{/* <div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120 }} size="small">
									<InputLabel>Patient ID</InputLabel>
									<Select
										value={this.state.filterPatient ? this.state.filterPatient : ""}
										name={'filterPatient'}
										onChange={this.getValue}
										input={<OutlinedInput label="Patient ID" />}
									>
									<MenuItem value={''} >None</MenuItem>
									{wPatients.map(function(val,i) {
										return (
											<MenuItem value={val} key={i}>{val}</MenuItem>
										);
									})}
									</Select>
								</FormControl>
							</div> */}
							
							<div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 135, maxWidth: 135 }} size="small">
									<InputLabel>Patient Name</InputLabel>
									<Select
										value={this.state.filterPatientName ? this.state.filterPatientName : ""}
										name={'filterPatientName'}
										onChange={this.getValue}
										input={<OutlinedInput label="Patient Name" />}
									>
									<MenuItem value={''} >None</MenuItem>
									{wPatientNames.map(function(val,i) {
										return (
											<MenuItem value={val} key={i}>{val}</MenuItem>
										);
									})}
									</Select>
								</FormControl>
							</div>
							
							
							<div className="duestartend">

								<DatePicker className="date-picker form-control " placeholderText="Due Date Start" selected={this.state.startDate} onChange={this.handleChangeStart}/> <div> - </div>
								<DatePicker className="date-picker form-control" placeholderText="Due Date End" selected={this.state.endDate} onChange={this.handleChangeEnd}/>
								{/* <LocalizationProvider dateAdapter={AdapterDayjs}>
									<label>Due Date Start & End:</label>
								<DatePicker selected={this.state.startDate} onChange={this.handleChangeStart}/>
							<DatePicker selected={this.state.endDate} onChange={this.handleChangeEnd}/> 
									<DateRangePicker
										label="Due Date Start - End"
										slots={{ field: WrappedSingleInputDateRangeField }}
										//localeText={{start: 'Due Date Start', end: 'End' }}
										ranges={[selectionRange]}
										onChange={this.handleSelect}
									/>
								</LocalizationProvider>*/}
								
							</div>
							
							<div className="wp-filter">
								<FormControl sx={{ m: 1, width: 68}} size="small">
								<button onClick={()=>this.resetFilter()} className="btn btn-danger" type="button">Reset</button>
								</FormControl>
							</div>
							{superViewIcon ?
								<div className="super-view" onClick={()=>this.superView(superViewId)}><span><img src={href+'/Icon-ColorInColor.png'} alt="View Details" width="35" height="35" /></span></div>
							:null}
						</div>
						<Paper sx={{ width: '100%', overflow: 'hidden' }}>
						<TableContainer>
						  <Table size="small" aria-label="a dense table" className="in-pro-table">
							<TableHead>
							  <TableRow>
							  
								<TableCell className={simpleViewArray.indexOf('t-cate') == -1 ? 't-cate hide-item' : 't-cate'}>Category</TableCell>
								<TableCell className={simpleViewArray.indexOf('t-subcate') == -1 ? 't-subcate hide-item' : 't-subcate'}>Subategory</TableCell>
								
								<TableCell className="t-workflow">Workflow</TableCell>
								<TableCell className="task-name-cl">Task</TableCell>
								
								<TableCell className={simpleViewArray.indexOf('t-label') == -1 ? 't-label hide-item' : 't-label'}>
									<TableSortLabel
										active={sortBy === 'label'}
										direction={sortOrder}
										onClick={() => this.handleSort('label')}
									>
									Label
									</TableSortLabel>
								</TableCell>
								
								<TableCell className={simpleViewArray.indexOf('t-role') == -1 ? 't-role hide-item' : 't-role'}>Role</TableCell>
								<TableCell className={simpleViewArray.indexOf('t-person') == -1 ? 't-person hide-item' : 't-person'}>Person</TableCell>
								
								<TableCell className="t-status">Status</TableCell>
								
								<TableCell className={simpleViewArray.indexOf('t-icon') == -1 ? 't-icon hide-item' : 't-icon'}>Status Icon</TableCell>
								
								<TableCell className={simpleViewArray.indexOf('t-start') == -1 ? 't-start hide-item' : 't-start'}>
									<TableSortLabel
										active={sortBy === 'start_date'}
										direction={sortOrder}
										onClick={() => this.handleSort('start_date')}
									>
									Start Date
									</TableSortLabel>
								</TableCell>
								
								<TableCell className={simpleViewArray.indexOf('t-due') == -1 ? 't-due hide-item' : 't-due'}>
									<TableSortLabel
										active={sortBy === 'due_date'}
										direction={sortOrder}
										onClick={() => this.handleSort('due_date')}
									>
									Due Date
									</TableSortLabel>
								</TableCell>
								
								
								<TableCell className={simpleViewArray.indexOf('t-activity') == -1 ? 't-activity hide-item' : 't-activity'}>
									<TableSortLabel
										active={sortBy === 'updated_at'}
										direction={sortOrder}
										onClick={() => this.handleSort('updated_at')}
									>
									Last Activity
									</TableSortLabel>
								</TableCell>
								
								
								
								<TableCell className={simpleViewArray.indexOf('t-days') == -1 ? 't-days hide-item' : 't-days'}>
									<TableSortLabel
										active={sortBy === 'x_days_number'}
										direction={sortOrder}
										onClick={() => this.handleSort('x_days_number')}
									>
									Assigned Days
									</TableSortLabel>
								</TableCell>
								
								<TableCell className={simpleViewArray.indexOf('t-a-days') == -1 ? 't-a-days hide-item' : 't-a-days'}>
									<TableSortLabel
										active={sortBy === 'days_spent'}
										direction={sortOrder}
										onClick={() => this.handleSort('days_spent')}
									>
									Actual Days
									</TableSortLabel>
								</TableCell>
								
								<TableCell className={simpleViewArray.indexOf('t-checklist') == -1 ? 't-checklist hide-item' : 't-checklist'}>Checklist</TableCell>
								<TableCell className={simpleViewArray.indexOf('t-details') == -1 ? 't-details hide-item' : 't-details'}>Details</TableCell>
								<TableCell className={simpleViewArray.indexOf('t-note') == -1 ? 't-note hide-item' : 't-note'}>Note</TableCell>
								<TableCell className={simpleViewArray.indexOf('t-qr') == -1 ? 't-qr hide-item' : 't-qr'}>QR Code</TableCell>
								<TableCell className={simpleViewArray.indexOf('t-note-icon') == -1 ? 't-note-icon hide-item' : 't-note-icon'}>WIP Notes</TableCell>
								<TableCell className={simpleViewArray.indexOf('t-goto') == -1 ? 't-goto hide-item' : 't-goto'}>Go-To</TableCell>
								<TableCell className={simpleViewArray.indexOf('t-link') == -1 ? 't-link hide-item' : 't-link'}>Link</TableCell>
								<TableCell className={simpleViewArray.indexOf('t-initated') == -1 ? 't-initated hide-item' : 't-initated'}>Initated by</TableCell>
								<TableCell className={simpleViewArray.indexOf('t-type') == -1 ? 't-type hide-item' : 't-type'}>Type</TableCell>
								<TableCell className={simpleViewArray.indexOf('t-company') == -1 ? 't-company hide-item' : 't-company'}>Insurance Company</TableCell>
								<TableCell className={simpleViewArray.indexOf('t-branch') == -1 ? 't-branch hide-item' : 't-branch'}>Branch</TableCell>
								<TableCell className={simpleViewArray.indexOf('t-fpatient') == -1 ? 't-fpatient hide-item' : 't-fpatient'}>Patient First Name</TableCell>
								<TableCell className={simpleViewArray.indexOf('t-lpatient') == -1 ? 't-lpatient hide-item' : 't-lpatient'}>Patient Last Name</TableCell>
								<TableCell className="t-view-d">View Details</TableCell>
							  </TableRow>
							</TableHead>
							
							  { sortedData && !viewWorkflowDetails ? 
								sortedData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
								  
									let personOption = '';
									if(rolePerson[row.task.role]){
										let persons = rolePerson[row.task.role].split(',');
										personOption = persons.map(function(val,i) {
											let name = val.split('|');
											return (
												<MenuItem key={i} value={name[0]}>{name[0]}</MenuItem>
											);
										})
									}
									
									let icon = 'x';
									if(row.task.status == 'To Do'){
										icon = 'red';
									}else if(row.task.status == 'In Progress'){
										icon = 'yellow';
									}else if(row.task.status == 'Complete'){
										icon = 'green';
									}else if(row.task.status == 'Pending'){
										icon = 'pending';
									}
									
									let checklist = row.task.checklist ? row.task.checklist.split(',') : [];
									let checklistChecked = '';
									if(checklist.length > 0){
										let checkedCount = 0;
										//let uncheckedCount = 0;
										checklist.map(function(val,i) {
											let str = val.split(':');
											if(str[1] == 'true'){
												checkedCount= checkedCount+1;
											}
										})
										
										checklistChecked = checkedCount+'/'+checklist.length;
									}
									
									//let notes = row.task.notes.reverse();
									let notes = row.task.notes;
					
									let noteHtml = '';
									if(notes){
										noteHtml = notes.map(function(val,i) {
											return (
												<div className="note-row-div" id={"note_"+row.id}>{val}</div>
											);
											
										})
									}
									
									let detailsNote = row.task.details_note;
									
									let incStr = detailsNote ? detailsNote.includes("<iframe") : '';
									
									let labelName = '';
									let labelBgColor = "#f4f5f5";
									let labelColor = "#000000";
									
									
									if(labels){
										labels.map(function(val,i) {
											if(row.label == val.id){
												labelName = val.name;
												labelBgColor = val.color;
												labelColor = '#ffffff';
											}
										})
									}
							
									return (<TableBody><TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
										<TableCell className={simpleViewArray.indexOf('t-cate') == -1 ? 't-cate hide-item' : 't-cate'}>{row.category}</TableCell>
										<TableCell className={simpleViewArray.indexOf('t-subcate') == -1 ? 't-subcate hide-item' : 't-subcate'}>{row.subcategoryname}</TableCell>
										<TableCell>{row.name}</TableCell>
										<TableCell>{row.task.name}</TableCell>
										
										
										<TableCell className={simpleViewArray.indexOf('t-label') == -1 ? 't-label hide-item' : 't-label'} ><a className="color-label" data-toggle="modal" data-target="#colorPopup" onClick={()=>this.handleWorkflowLabel(row.id,row.label)} style={{backgroundColor:labelBgColor,color:labelColor}}>{labelName}&nbsp;</a></TableCell> 
										
										
										<TableCell className={simpleViewArray.indexOf('t-role') == -1 ? 't-role hide-item' : 't-role'}>{row.task.role}</TableCell>
										<TableCell className={simpleViewArray.indexOf('t-person') == -1 ? 't-person hide-item' : 't-person'}>
											<FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120, margin:0 }} size="small">
											<Select
												value={row.task.person ? row.task.person : ' '}
												name={row.task.id+'|'+row.task.role}
												onChange={this.updatePerson}
												style={{ width: 125,height: 28 }}
											>
											{row.task.person == '' ?
												<MenuItem value=' '>Please Select</MenuItem>
											:null}
											{personOption}
											</Select>
											</FormControl>
										</TableCell>
										
										<TableCell className="t-status">
										<FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120, margin:0 }} size="small">
										<Select
											value={row.task.status}
											name={row.task.id+'|'+row.task.task_start_date}
											onChange={this.updateTaskOption}
											style={{ width: 120,height: 28 }}
										>
										<MenuItem value='Pending'>Pending</MenuItem>
										<MenuItem value='To Do'>To Do</MenuItem>
										<MenuItem value='In Progress'>In Progress</MenuItem>
										<MenuItem value='Complete'>Complete</MenuItem>
										<MenuItem value='N/A'>N/A</MenuItem>
										</Select>
										</FormControl>
										</TableCell>
										
										<TableCell className={simpleViewArray.indexOf('t-icon') == -1 ? 't-icon hide-item' : 't-icon'}><span className="task-icon"><img src={href+'/'+icon+'.png'} alt="Status" width="15" height="15" /></span></TableCell>
										
										<TableCell className={simpleViewArray.indexOf('t-start') == -1 ? 't-start hide-item' : 't-start'}>{moment(row.start_date).format('MM/DD/YYYY')}</TableCell>
										<TableCell className={simpleViewArray.indexOf('t-due') == -1 ? 't-due hide-item' : 't-due'}>{moment(row.due_date).format('MM/DD/YYYY')}</TableCell>
										
										<TableCell className={simpleViewArray.indexOf('t-activity') == -1 ? 't-activity hide-item' : 't-activity'}>{moment(row.task.updated_at).format('MM/DD/YYYY')}</TableCell>
										<TableCell className={simpleViewArray.indexOf('t-days') == -1 ? 't-days hide-item' : 't-days'}>{row.task.x_days_number}</TableCell>
										<TableCell className={simpleViewArray.indexOf('t-a-days') == -1 ? 't-a-days hide-item' : 't-a-days'}>{row.task.days_spent}</TableCell>
										
										
										
										<TableCell className={simpleViewArray.indexOf('t-checklist') == -1 ? 't-checklist hide-item' : 't-checklist'}><span className="task-icon task-note" data-toggle="modal" data-target="#checkListPopup" onClick={() => { this.taskChecklist(row.task.id,row.task.task_uid,row.task.name,row.task.job_id,row.task.wid,row.task.task_id,row.task.checklist,row.task.status_change,row.task.task_start_date) } }>{checklistChecked}</span></TableCell>
										
										<TableCell className={simpleViewArray.indexOf('t-details') == -1 ? 't-details hide-item' : 't-details'}><span className="task-icon task-info" data-toggle="modal" data-target="#taskDetails" onClick={() => { this.taskDetails(row) } }><i className="fa fa-info-circle"></i></span></TableCell>
										{row.task.details_note ? 
										<TableCell className={simpleViewArray.indexOf('t-note') == -1 ? 't-note hide-item' : 't-note'}><span className="task-icon task-note" data-toggle="modal" data-target="#taskNote" onClick={() => { that.detailsNote(row.task.details_note) } }>
										{incStr ? 
											<img src={href+'/Icon-Video.png'} alt="View" width="22" height="22" />
										:
											<img src={href+'/Icon-View.png'} alt="View" width="26" height="14" />
										}
										
										</span></TableCell>
										:
										<TableCell className={simpleViewArray.indexOf('t-note') == -1 ? 't-note hide-item' : 't-note'}></TableCell>
										}
										<TableCell className={simpleViewArray.indexOf('t-qr') == -1 ? 't-qr hide-item' : 't-qr'}><button onClick={() => { that.getQrCode(row.task.task_uid,row.name,row.task.name) } } data-toggle="modal" data-target="#qrCodePopup" className="btn qr-code-btn" type="button"><img src={href+'/Icon-QR.png'} alt="OR" width="18" height="18" /></button></TableCell>
										
										<TableCell className={simpleViewArray.indexOf('t-note-icon') == -1 ? 't-note-icon hide-item' : 't-note-icon'}><span className="task-icon task-note" data-toggle="modal" data-target="#taskWipNote" onClick={() => { that.wipNote(row.task.job_id,row.task.task_uid,row.task.wid,row.task.task_id) } }><img src={href+'/icon-note.png'} alt="Status" width="20" height="20" /></span></TableCell>
										
										<TableCell className={simpleViewArray.indexOf('t-goto') == -1 ? 't-goto hide-item' : 't-goto'}>
										{row.task.gotolink ?
										
										
										<a className="goto-link-a" href={row.task.gotolink} target="_blank" onClick={() => { this.gotoLinkUrl(row)}}><span className="task-icon"><img src={href+'/gotolink.png'} alt="Status" width="18" height="18" /></span></a>
										
										:null}
										</TableCell>
										<TableCell className={simpleViewArray.indexOf('t-link') == -1 ? 't-link hide-item' : 't-link'}><a href={row.link_url ? row.link_url : 'javascript:void(0)'} target="_blank"><span className="task-icon"><img src={href+'/icon-sync.png'} alt="Status" width="18" height="18" /></span></a></TableCell>
										<TableCell className={simpleViewArray.indexOf('t-initated') == -1 ? 't-initated hide-item' : 't-initated'}>{row.username}</TableCell>
										<TableCell className={simpleViewArray.indexOf('t-type') == -1 ? 't-type hide-item' : 't-type'}>{row.type}</TableCell>
										<TableCell className={simpleViewArray.indexOf('t-company') == -1 ? 't-company hide-item' : 't-company'}>{row.insurance_company}</TableCell>
										<TableCell className={simpleViewArray.indexOf('t-branch') == -1 ? 't-branch hide-item' : 't-branch'}>{row.branch_id}</TableCell>
										<TableCell className={simpleViewArray.indexOf('t-fpatient') == -1 ? 't-fpatient hide-item' : 't-fpatient'}>{row.patient_first_name}</TableCell>
										<TableCell className={simpleViewArray.indexOf('t-lpatient') == -1 ? 't-lpatient hide-item' : 't-lpatient'}>{row.patient_last_name}</TableCell>
										<TableCell className="t-view-d"><span id={"view_"+row.id} className="view-workflow-details" onClick={() => { that.workflowDetails(row.id,row.workflow_days_spent) } }><img src={href+'/view-details.png'} alt="View Details" width="22" height="22" /></span></TableCell>
									</TableRow>
									{noteHtml != '' ? 
									<TableRow>
									<TableCell id={"note_row"+row.id} className="note-row" sx={{display: 'none'}} colspan="23">{noteHtml}</TableCell>
									</TableRow>
									:null}
									</TableBody>
									)
									
								})
							  :null}
						  </Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[5, 10, 20, 50, 100]}
							component="div"
							count={sortedData.length}
							rowsPerPage={this.state.rowsPerPage}
							page={this.state.page}
							onPageChange={this.handleChangePage}
							onRowsPerPageChange={this.handleChangeRowsPerPage}
						  />
						</Paper>
						
						<div className="modal" id={"taskDetails"} role="dialog">
							<div className="modal-dialog modal-lg custom-modal mds-description-modal">
								<div className="modal-content">
								  <div className="modal-header">
									<h5 className="modal-title">Workflow Initiate Details</h5>
									<button type="button" className="close" data-dismiss="modal">&times;</button>
								  </div>
								  <div className="modal-body">
								  {taskDetails ?
								  <table className="table table-bordered task-details-tb">
									  <tr><th>Workflow_ID</th><td>{taskDetails.workflow_id}</td> </tr>
									  <tr><th>Facility_ID</th><td>{taskDetails.facility_id}</td></tr>
									  <tr><th>Patient_Branch_ID</th><td>{taskDetails.patient_branch_id}</td></tr>
									  <tr><th>Branch_Id</th><td>{taskDetails.branch_id}</td></tr>
									  <tr><th>User_Id</th><td>{taskDetails.user_id}</td> </tr>
									  <tr><th>Username</th><td>{taskDetails.username}</td> </tr>
									  <tr><th>TreatingPractitioner_ID</th><td>{taskDetails.treating_practitioner_id}</td> </tr>
									  <tr><th>Patient_ID</th><td>{taskDetails.patient_id}</td> </tr>
									  <tr><th>Patient_FirstName</th><td>{taskDetails.patient_first_name}</td> </tr>
									  <tr><th>Patient_LastName</th><td>{taskDetails.patient_last_name}</td> </tr>
									  <tr><th>Wip_ID</th><td>{taskDetails.wip_id}</td> </tr>
									  <tr><th>Type</th><td>{taskDetails.type}</td> </tr>
									  <tr><th>Insurance_company</th><td>{taskDetails.insurance_company}</td> </tr>
									  <tr><th>Rx_ID</th><td>{taskDetails.rx_id}</td> </tr>
									  <tr><th>Claim_ID</th><td>{taskDetails.claim_id}</td> </tr>
									  <tr><th>Visit_ID</th><td>{taskDetails.visit_id}</td> </tr>
									  <tr><th>InitiatingForm_ID</th><td>{taskDetails.initiating_form_id}</td> </tr>
									  <tr><th>Link_Url</th><td>{taskDetails.link_url}</td> </tr>
								  </table>
								  :null}
								  </div>
								</div>
							</div>
						</div>
						
						<div className="modal" id={"taskNote"} role="dialog">
							<div className="modal-dialog modal-lg custom-modal mds-description-modal">
								<div className="modal-content">
								  <div className="modal-header">
									<h5 className="modal-title">Details Note</h5>
									<button type="button" className="close" data-dismiss="modal">&times;</button>
								  </div>
								  <div className="modal-body">
								  {ReactHtmlParser(this.state.taskNote)}
								  </div>
								  <div className="modal-footer">
										<div className="popup-btn-com">
											<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
										</div>
								  </div>
								</div>
							</div>
						</div>
						
						<div className="modal" id={"checkListPopup"} role="dialog">
							<div className="modal-dialog modal-lg custom-modal mds-description-modal">
								<div className="modal-content">
								  <div className="modal-header">
									<h6>{this.state.taskName}</h6>
									<button className="close custom-btn" type="button" data-dismiss="modal">&times;</button>
								  </div>
								  <div className="modal-body">
								  <div className="list-header"><input type="checkbox" checked="checked" disabled /> Checklist</div>

								  <div className="custom-progress-bar"><span>{parseInt(this.state.listPercentage) +'%'}</span><progress id="file" value={this.state.checkedCount} max={checklist.length}></progress></div>
									
									{checklist.map((val, index) => {
										let listtext = val.split(':');
										
										let checked = '';
										let classs = '';
										if(listtext[1] == 'true'){
											checked = 'checked';
											classs = 'checklist-completed';
										}
										
										return(
											<div className={'task-list-option'}>
												<input name={listtext[0]} type="checkbox" value={listtext[0]} onClick={()=>this.handalChecklistOption(listtext[0])} checked={checked} /> <span className={classs}> {listtext[0]}</span>
											</div>
										);
									})}
								  </div>
								  <div className="modal-footer">
										<div className="popup-btn-com">
											<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.saveChecklistOption}>Save</button>&nbsp;
											<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
										</div>
								  </div>
								</div>
							</div>
						</div>
						
						<div className="modal" id={"taskWipNote"} role="dialog">
							<div className="modal-dialog modal-lg custom-modal mds-description-modal">
								<div className="modal-content">
								  <div className="modal-header">
									<h5 className="modal-title">WIP Note</h5>
									<button type="button" className="close" data-dismiss="modal">&times;</button>
								  </div>
								  <div className="modal-body">
									<div className="add-wip-note2">
										<input className="form-control input-wip-note" name="addWipNote" value={this.state.addWipNote} onChange={this.getValue} />
										<button type="button" className="btn btn-info btn-wip-note" onClick={this.handleWipNote}>Add</button>
									</div>
									<div id="wipNote" className="wip-Note-box"></div>
								  </div>
								  <div className="modal-footer">
										<div className="popup-btn-com">
											<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
										</div>
								  </div>
								</div>
							</div>
						</div>
						
						<div className="modal" id={"colorPopup"} role="dialog">
							<div className="modal-dialog modal-lg custom-modal mds-description-modal">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title">Assign Label</h5>
										<button type="button" className="close" data-dismiss="modal">&times;</button>
									</div>
									<div className="modal-body">
										<Paper sx={{ width: '100%', overflow: 'hidden' }}>
											<TableContainer>
											  <Table size="small" aria-label="a dense table" className="in-pro-table">
												<TableHead>
												  <TableRow>
													<TableCell className="color-id"></TableCell>
													<TableCell className="color-name">Label</TableCell>
													<TableCell>Color</TableCell>
													<TableCell className="color-action">Actions</TableCell>
												  </TableRow>
												</TableHead>
												<TableBody>
													{labels ? 
													labels.slice(this.state.page1 * this.state.rowsPerPage1, this.state.page1 * this.state.rowsPerPage1 + this.state.rowsPerPage1).map((row) => {
														let labelName = this.state.labelName ? this.state.labelName : row.name;
														
														return (<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
															
															<TableCell><input name={row.id} type="radio" name="assign" onChange={()=>this.handleColorCheckbox(row.id)} checked={this.state.assignId == row.id ? 'checked' : ''}  /></TableCell>
															{editLable == row.id ?
																<TableCell><input type="text" name="labelName" value={labelName} placeholder="Add Label" onChange={this.getValue}/></TableCell>
															:
																<TableCell>{row.name}</TableCell>
															}
															
															{editLable == row.id ?
															<TableCell><input type="color" name={'bg-'+row.id} onChange={this.handleColorBg} value={this.state['bg-'+row.id] ? this.state['bg-'+row.id] : row.color} /><span className="color-code">{this.state['bg-'+row.id] ? this.state['bg-'+row.id] : row.color}</span></TableCell>
															:
															<TableCell><input type="color" name={'bg-'+row.id} onChange={this.handleColorBg} value={this.state['bg-'+row.id] ? this.state['bg-'+row.id] : row.color} disabled/><span className="color-code">{this.state['bg-'+row.id] ? this.state['bg-'+row.id] : row.color}</span></TableCell>
															}
															
															<TableCell>
															{editLable == row.id ?
															<button id={'fId-'+row.id} type="button" className="btn" onClick={()=>this.updateLabel(row.id,row.name,row.color)}><i className="fa fa-check"></i></button>
															:
															<button id={'fId-'+row.id} type="button" className="btn" onClick={()=>this.editLabel(row.id)}><i className="fa fa-pencil"></i></button>
															}
															
															{editLable == row.id ?
															<button type="button" className="btn" onClick={()=>this.hideEditLabel()}><i className="fa fa-times" aria-hidden="true"></i></button>
															:
															<button type="button" className="btn" onClick={()=>this.deleteLabel(row.id)}><i className="fa fa-trash"></i></button>
															}
															
															</TableCell> 
														</TableRow>
														)
													})
													:null}
													{showNewLabel ? 
													<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
														<TableCell><input type="checkbox" disabled/></TableCell>
														<TableCell><input type="text" name="newLabel" placeholder="Add Label" onChange={this.getValue}/></TableCell>
														
														<TableCell><input type="color" name="newColor" onChange={this.getValue} value={this.state.newColor} /><span className="color-code">{this.state.newColor}</span></TableCell>
														
														<TableCell>
														<button type="button" className="btn" onClick={()=>this.saveLabel()}><i className="fa fa-check"></i></button>
														<button type="button" className="btn" onClick={()=>this.hideNewLabel()}><i className="fa fa-times" aria-hidden="true"></i></button>
														</TableCell> 
													</TableRow>
													:null}
												</TableBody>
											  </Table>
											</TableContainer>
											<TablePagination
												rowsPerPageOptions={[5, 10, 20, 50, 100]}
												component="div"
												count={labels.length}
												rowsPerPage={this.state.rowsPerPage1}
												page={this.state.page1}
												onPageChange={this.handleChangePage1}
												onRowsPerPageChange={this.handleChangeRowsPerPage1}
											/>
											<div className="add-label-btn" onClick={()=>this.showAddLabel()}><i className="fa fa-plus" aria-hidden="true"></i></div>
											</Paper>
											
									</div>
									<div className="modal-footer">
										{this.state.saveBtn ? 
											<div className="assgin-btn">
												<button type="button" className="btn btn-primary" onClick={()=>this.assignLabelToWorkflow()}>Save</button>
												&nbsp;
												<button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
											</div>
										:null}
									</div>
								</div>
							</div>
						</div>
						
						<div className="modal" id={"qrCodePopup"} role="dialog">
							<div className="modal-dialog modal-lg custom-modal qr-code-modal">
								<div className="modal-content qr-code-modal">
									<div className="modal-header">
										<button type="button" className="close" data-dismiss="modal">&times;</button>
									</div>
									<div className="modal-body text-center">
										<div id="qr-code-img"><img src={this.state.uniqueQrCode} alt="QR" width="150" height="150" />
										</div>
										<div className="qr-code-des">
											
											<div className="mr-3"><b>Workflow:</b> {this.state.workflowName}</div>
											<div className="mr-3"><b>Task Name:</b> {this.state.taskName}</div>
											<div className="qr-code-button">
											<button onClick={()=>this.copyQrImage(this.state.copyQrCode)} className="btn copy-code-btn" type="button"><i className="fa fa-copy" aria-hidden="true"></i></button>
											<button onClick={()=>this.saveQrImage(this.state.copyQrCode,this.state.taskName)} className="btn copy-code-btn" type="button"><i className="fa fa-download" aria-hidden="true"></i></button>
											<button onClick={()=>this.printQrImage(this.state.copyQrCode,this.state.taskName)} className="btn copy-code-btn" type="button"><i className="fa fa-print" aria-hidden="true"></i></button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
									
					</div>
				</div>
			);
		
		}
		
		if(viewWorkflowDetails){
			return (
				<div className="workflow-first-task">
					<div className="wdata-table-div">
						<div className="row">
							<div className="col-md-12 mb-3 text-center">
								<h4>Active Workflow Details</h4>
								{ this.state.superViewV2 ? 
								<span className="back-to-view" onClick={this.handleBackViewV2}>Back to Super View</span>
								:
								<span className="back-to-view" onClick={this.handleBackView}>Back to Main View</span>
								}
								<div className="show-all-note">
									<label className="showAllSwitch">
										<input id="showAllNotes" type="checkbox" name="showAllNotes" onClick={()=>this.showAllNotes()} />
										<span className="slider round showAllSlide"></span>
									</label>
									<span><b>Show all Notes</b></span>
								</div>
							</div>
						</div>
						<div className="job-header-section">
							<span><b>Patient Name:</b> {workflowJob.patient_first_name}</span>
							<span><b>Patient ID:</b> {workflowJob.patient_id}</span>
							<span><b>Branch:</b> {workflowJob.branch_id}</span>
							<span><b>Insurance Company:</b> {workflowJob.insurance_company}</span>
							
							<span className="pull-right">Total Days Since Job was initiated : <b>{this.state.workflowDaysSpent}</b></span>
							<span className="pull-right">Total Days assigned : <b>{assignedDays}</b></span>
						</div>
						
						<Paper sx={{ width: '100%', overflow: 'hidden' }}>
						<TableContainer>
						  <Table size="small" aria-label="a dense table">
							<TableHead>
							  <TableRow>
								<StyledTableCell>Status Icon</StyledTableCell>
								<StyledTableCell>Task Name</StyledTableCell>
								<StyledTableCell>Responsible Role</StyledTableCell>
								<StyledTableCell>Responsible Person</StyledTableCell>
								<StyledTableCell>Status</StyledTableCell>
								<StyledTableCell>Start Date</StyledTableCell>
								<StyledTableCell>Due Date</StyledTableCell>
								<StyledTableCell>Assigned Days</StyledTableCell>
								<StyledTableCell>Actual Days</StyledTableCell>
								<StyledTableCell>View Task Details</StyledTableCell>
								<StyledTableCell>Checklist</StyledTableCell>
								<StyledTableCell>QR Code</StyledTableCell>
								<StyledTableCell>WIP Notes</StyledTableCell>
								<StyledTableCell>Go-To</StyledTableCell>
								
							  </TableRow>
							</TableHead>
							
							{ workflowJobTasks ? 
								workflowJobTasks.slice(this.state.page2 * this.state.rowsPerPage2, this.state.page2 * this.state.rowsPerPage2 + this.state.rowsPerPage2).map((row) => {
									console.log('row->',row);
									if(row.task_id){
									let icon = 'x';
									if(row.status == 'To Do'){
										icon = 'red';
									}else if(row.status == 'In Progress'){
										icon = 'yellow';
									}else if(row.status == 'Complete'){
										icon = 'green';
									}else if(row.status == 'Pending'){
										icon = 'pending';
									}
									assignedDays += parseInt(row.x_days_number);
									
									
									let className = '';
									let days_assigned = parseInt(row.x_days_number);
									let days_spent = parseInt(row.days_spent);
									
									if(days_assigned < days_spent){
										className = 'row-red';
									}
									
									if(days_assigned == days_spent){
										className = 'row-yellow';
									}
									
									let checklist = row.checklist ? row.checklist.split(',') : [];
									let checklistChecked = '';
									if(checklist.length > 0){
										let checkedCount = 0;
										//let uncheckedCount = 0;
										checklist.map(function(val,i) {
											let str = val.split(':');
											if(str[1] == 'true'){
												checkedCount= checkedCount+1;
											}
										})
										
										checklistChecked = checkedCount+'/'+checklist.length;
									}
									
									//let notes = row.notes.reverse();
									let notes = row.notes;
										
									let noteHtml = '';
									if(notes){
										noteHtml = notes.map(function(val,i) {
											return (
												<div className="note-row-div" id={"new_note_"+row.id}>{val}</div>
											);
											
										})
									}
									
									let personOption = '';
									if(rolePerson[row.role]){
										let persons = rolePerson[row.role].split(',');
										personOption = persons.map(function(val,i) {
											let name = val.split('|');
											return (
												<MenuItem key={i} value={name[0]}>{name[0]}</MenuItem>
											);
										})
									}
									
									let detailsNote = row.details_note;
									
									let incStr = detailsNote ? detailsNote.includes("<iframe") : '';
									
									return (<TableBody><TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
										<TableCell><span className="task-icon"><img src={href+'/'+icon+'.png'} alt="Status" width="18" height="18" /></span></TableCell>
										<TableCell>{row.task_name}</TableCell>
										<TableCell>{row.role}</TableCell>
										<TableCell>
										<FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120, margin:0 }} size="small">
										<Select
											value={row.person ? row.person : ' '}
											name={row.id+'|'+row.role}
											onChange={this.updatePerson}
											style={{ width: 125,height: 28 }}
										>
										{row.person == '' ?
											<MenuItem value=' '>Please Select</MenuItem>
										:null}
										{personOption}
										</Select>
										</FormControl>
										
										
										</TableCell>
										<TableCell>
										<FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120, margin:0 }} size="small">
										<Select
											value={row.status}
											name={row.id+'|'+row.task_start_date}
											onChange={this.updateTaskOption}
											style={{ width: 120,height: 28 }}
										>
										<MenuItem value='Pending'>Pending</MenuItem>
										<MenuItem value='To Do'>To Do</MenuItem>
										<MenuItem value='In Progress'>In Progress</MenuItem>
										<MenuItem value='Complete'>Complete</MenuItem>
										<MenuItem value='N/A'>N/A</MenuItem>
										</Select>
										</FormControl>
										</TableCell>
										<TableCell>{moment(row.start_date).format('MM/DD/YYYY')}</TableCell>
										<TableCell>{moment(row.due_date).format('MM/DD/YYYY')}</TableCell>
										<TableCell>{row.x_days_number}</TableCell>
										<TableCell>{row.days_spent}</TableCell>
										{ row.details_note ? 
										<TableCell className="text-center"><span className="task-icon task-note" data-toggle="modal" data-target="#taskNote" onClick={() => { that.detailsNote(row.details_note) } }>
										{incStr ? 
											<img src={href+'/Icon-Video.png'} alt="View" width="22" height="22" />
										:
											<img src={href+'/Icon-View.png'} alt="View" width="26" height="14" />
										}
										</span></TableCell>
										:
										<TableCell className="text-center"></TableCell>
										}
										
										<TableCell className="text-center"><span className="task-icon task-note" data-toggle="modal" data-target="#checkListPopup2" onClick={() => { that.taskChecklist(row.id,row.task_uid,row.task_name,row.job_id,row.wid,row.task_id,row.checklist,row.status_change,row.task_start_date) } }>{checklistChecked}</span></TableCell>
										<TableCell className="text-center"><button onClick={() => { that.getQrCode(row.task_uid,row.workflow_name,row.task_name) } } data-toggle="modal" data-target="#qrCodePopup2" className="btn qr-code-btn" type="button"><img src={href+'/Icon-QR.png'} alt="OR" width="18" height="18" /></button></TableCell>
										<TableCell className="text-center"><span className="task-icon task-note" data-toggle="modal" data-target="#taskWipNote" onClick={() => { that.wipNote(row.job_id,row.task_uid,row.wid,row.task_id) } }><img src={href+'/icon-note.png'} alt="Status" width="20" height="20" /></span></TableCell>
										<TableCell className="text-center">
										{row.gotolink ?
										<a href={row.gotolink ? row.gotolink : 'javascript:void(0)'} target="_blank"><span className="task-icon"><img src={href+'/gotolink.png'} alt="Status" width="18" height="18" /></span></a>
										:null}
										</TableCell>
										
									  
									</TableRow>
									{noteHtml != '' ? 
									<TableRow>
									<TableCell id={"note_row"+row.id} className="note-row" sx={{display: 'none'}} colspan="23">{noteHtml}</TableCell>
									</TableRow>
									:null}
									
									</TableBody>)
									}
								})
							:null}
							
						  </Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[5, 10, 20, 50, 100]}
							component="div"
							count={workflowJobTasks.length}
							rowsPerPage={this.state.rowsPerPage2}
							page={this.state.page2}
							onPageChange={this.handleChangePage2}
							onRowsPerPageChange={this.handleChangeRowsPerPage2}
						/>
						</Paper>
						
						<div className="modal" id={"taskNote"} role="dialog">
							<div className="modal-dialog modal-lg custom-modal mds-description-modal">
								<div className="modal-content">
								  <div className="modal-header">
									<h5 className="modal-title">Details Note</h5>
									<button type="button" className="close" data-dismiss="modal">&times;</button>
								  </div>
								  <div className="modal-body">
								  {ReactHtmlParser(this.state.taskNote)}
								  </div>
								  <div className="modal-footer">
										<div className="popup-btn-com">
											<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
										</div>
								  </div>
								</div>
							</div>
						</div>
						
						<div className="modal" id={"checkListPopup2"} role="dialog">
							<div className="modal-dialog modal-lg custom-modal mds-description-modal">
								<div className="modal-content">
								  <div className="modal-header">
									<h6>{this.state.taskName}</h6>
									<button className="close custom-btn" type="button" data-dismiss="modal">&times;</button>
								  </div>
								  <div className="modal-body">
								  <div className="list-header"><input type="checkbox" checked="checked" disabled /> Checklist</div>

								  <div className="custom-progress-bar"><span>{parseInt(this.state.listPercentage) +'%'}</span><progress id="file" value={this.state.checkedCount} max={checklist.length}></progress></div>
									
									{checklist.map((val, index) => {
										let listtext = val.split(':');
										
										let checked = '';
										let classs = '';
										if(listtext[1] == 'true'){
											checked = 'checked';
											classs = 'checklist-completed';
										}
										
										return(
											<div className={'task-list-option'}>
												<input name={listtext[0]} type="checkbox" value={listtext[0]} onClick={()=>this.handalChecklistOption(listtext[0])} checked={checked} /><span className={classs} > {listtext[0]}</span>
											</div>
										);
									})}
								  </div>
								  <div className="modal-footer">
										<div className="popup-btn-com">
											<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.saveChecklistOption}>Save</button>&nbsp;
											<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
										</div>
								  </div>
								</div>
							</div>
						</div>
						
						<div className="modal" id={"taskWipNote"} role="dialog">
							<div className="modal-dialog modal-lg custom-modal mds-description-modal">
								<div className="modal-content">
								  <div className="modal-header">
									<h5 className="modal-title">WIP Note</h5>
									<button type="button" className="close" data-dismiss="modal">&times;</button>
								  </div>
								  <div className="modal-body">
									<div className="add-wip-note2">
										<input className="form-control input-wip-note" name="addWipNote" value={this.state.addWipNote} onChange={this.getValue} />
										<button type="button" className="btn btn-info btn-wip-note" onClick={this.handleWipNote}>Add</button>
									</div>
									<div id="wipNote" className="wip-Note-box"></div>
								  </div>
								  <div className="modal-footer">
										<div className="popup-btn-com">
											<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
										</div>
								  </div>
								</div>
							</div>
						</div>
							
						<div className="modal" id={"qrCodePopup2"} role="dialog">
							<div className="modal-dialog modal-lg custom-modal qr-code-modal">
								<div className="modal-content qr-code-modal">
									<div className="modal-header">
										<button type="button" className="close" data-dismiss="modal">&times;</button>
									</div>
									<div className="modal-body text-center">
									<div id="qr-code-img"><img src={this.state.uniqueQrCode} alt="QR" width="150" height="150" /></div>
										<div className="qr-code-des">
											
											<div className="mr-3"><b>Workflow:</b> {this.state.workflowName}</div>
											<div className="mr-3"><b>Task Name:</b> {this.state.taskName}</div>
											<div className="qr-code-button">
											<button onClick={()=>this.copyQrImage(this.state.copyQrCode)} className="btn copy-code-btn" type="button"><i className="fa fa-copy" aria-hidden="true"></i></button>
											<button onClick={()=>this.saveQrImage(this.state.copyQrCode,this.state.taskName)} className="btn copy-code-btn" type="button"><i className="fa fa-download" aria-hidden="true"></i></button>
											<button onClick={()=>this.printQrImage(this.state.copyQrCode,this.state.taskName)} className="btn copy-code-btn" type="button"><i className="fa fa-print" aria-hidden="true"></i></button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			);
		
		}
		
		if(superViewShow && workflowSuperJobs){
			return (
				<div className="workflow-super-view">
					<div className="wdata-table-div">
						<div className="row">
							<div className="col-md-12 mb-3">
								<h4>Super Grid View: {filterWorkflow}</h4>
								
								<span className="back-to-view" onClick={this.handleBackView}>Back to Main View</span>
								{simpleView ?
								<span className="simple-to-view" onClick={this.handleFullView}>Full View</span>
								:
								<span className="simple-to-view" onClick={this.handleSimpleView}>Simple View</span>
								}
							</div>
						</div>
						{ workflowSuperJobs.length > 0 ?
						<Paper sx={{ width: '100%', overflow: 'hidden' }}>
						<TableContainer>
						  <Table size="small" aria-label="a dense table">
							<TableHead>
							  <TableRow>
								<TableCell>Patient Name</TableCell>
								<TableCell>Insurance Company</TableCell>
								<TableCell>Branch</TableCell>
								<TableCell>Type</TableCell>
								<TableCell>Assigned Days</TableCell>
								<TableCell>Actual Days</TableCell>
								{tasksHeader}
								<TableCell>View Details</TableCell>

							  </TableRow>
							</TableHead>
							<TableBody>
							 
							 { workflowSuperJobs.slice(this.state.page3 * this.state.rowsPerPage3, this.state.page3 * this.state.rowsPerPage3 + this.state.rowsPerPage3).map((row) => {
									if(row.WF_Job_ID){
										let workflowSuperJobTask = row.Tasks;
										let dueDate = moment(row.Due_Date).format('MM/DD/YYYY');
										
										let n = 0;
										let allComlete = 1;
										let count = workflowSuperJobTask.length;
										let greaterCount = that.state.greaterCount;
										
										let colspan = greaterCount - count;
										let tasksRow = workflowSuperJobTask.map(function(row1,i) {
											
											if(row1.Status != 'Complete' && n==0){
												dueDate = moment(row1.Due_Date).format('MM/DD/YYYY');
												n=1;
											}
											
											if(row1.Status != 'Complete'){
												allComlete = 0;
											}
											
											let icon = 'x';
											if(row1.Status == 'To Do'){
												icon = 'red';
											}else if(row1.Status == 'In Progress'){
												icon = 'yellow';
											}else if(row1.Status == 'Complete'){
												icon = 'green';
											}else if(row1.Status == 'Pending'){
												icon = 'pending';
											}
											return (<TableCell className="ext-fields"><a target="_blank" href={row1.gotolink ? row1.gotolink : 'javascript:void(0)'}><span className="task-icon"><img src={href+'/'+icon+'.png'} alt="Status" width="18" height="18" /></span></a></TableCell>);
										})
										
										if(that.state.startDate){
											let sDate = moment(that.state.startDate).format('MM/DD/YYYY');
											if(dueDate < sDate){
												return false;
											}
										}
										
										if(that.state.endDate){
											let eDate = moment(that.state.endDate).format('MM/DD/YYYY');
											if(dueDate > eDate){
												return false;
											}
										}
										let className = '';
										let alertIcon = 0;
										let days_assigned = parseInt(row.workflow_days_assigned);
										let days_spent = parseInt(row.wokflows_days_spent);
										
										if(days_assigned > days_spent){
											if(allComlete == 1){
												className = 'row-green';
											}else{
												className = '';
											}
										}
										
										if(days_assigned < days_spent){
											
											if(allComlete == 1){
												className = 'row-green';
												alertIcon = 1
											}else{
												
												className = 'row-red';
											}
										}
										
										if(days_assigned == days_spent && allComlete != 1){
											
											className = 'row-yellow';
										}
										
										
										const textTd = [];
										for (let i = 0; i < colspan; i++) {
											textTd.push(<TableCell>&nbsp;</TableCell>);
										}
										if(simpleView){
											tasksRow = '';
										}
										return (<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell>{row.patient_first_name}</TableCell>
											<TableCell>{row.insurance_company}</TableCell>
											<TableCell>{row.Branch}</TableCell>
											<TableCell>{row.Type}</TableCell>
											<TableCell>{days_assigned}</TableCell>
											<TableCell className={className}>{days_spent} {alertIcon == 1 ? <span>!</span> : ''}</TableCell>
											{tasksRow}
											{textTd}
											<TableCell className="new-super-view" colspan={colspan}><span id={"view_"+row.WF_Job_ID} className="view-workflow-details" onClick={() => { that.workflowDetailsV2(row.WF_Job_ID) } }><img src={href+'/view-details.png'} alt="View Details" width="20" height="20" /></span></TableCell>
										</TableRow>);
									}
								})
							 }
							</TableBody>
						  </Table>
							<TablePagination
								rowsPerPageOptions={[5, 10, 20, 50, 100]}
								component="div"
								count={workflowSuperJobs.length}
								rowsPerPage={this.state.rowsPerPage3}
								page={this.state.page3}
								onPageChange={this.handleChangePage3}
								onRowsPerPageChange={this.handleChangeRowsPerPage3}
							/>
						</TableContainer>
						</Paper>
						:null}
					</div>
				</div>
			);
		
		}
	}	
}
